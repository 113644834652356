exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-authors-js": () => import("./../../../src/pages/authors.js" /* webpackChunkName: "component---src-pages-authors-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-authors-barrett-eubanks-md-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/authors/barrett-eubanks-md.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-authors-barrett-eubanks-md-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-authors-want-to-contribute-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/authors/want-to-contribute.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-authors-want-to-contribute-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-categories-basecamp-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/categories/basecamp.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-categories-basecamp-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-categories-cataracts-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/categories/cataracts.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-categories-cataracts-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-categories-icl-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/categories/icl.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-categories-icl-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-categories-injury-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/categories/injury.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-categories-injury-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-categories-keratoconus-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/categories/keratoconus.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-categories-keratoconus-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-categories-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/categories/lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-categories-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-categories-prk-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/categories/prk.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-categories-prk-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-categories-smile-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/categories/smile.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-categories-smile-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-about-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/pages/about.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-about-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-articles-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/pages/articles.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-articles-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-authors-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/pages/authors.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-authors-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-privacy-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-privacy-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-terms-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/pages/terms.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-terms-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-accidentally-looked-at-uv-light-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/accidentally-looked-at-uv-light.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-accidentally-looked-at-uv-light-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-accidentally-rubbed-eyes-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/accidentally-rubbed-eyes-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-accidentally-rubbed-eyes-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-air-puff-eye-test-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/air-puff-eye-test.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-air-puff-eye-test-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-alcohol-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/alcohol-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-alcohol-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-anatomy-of-the-eye-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/anatomy-of-the-eye.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-anatomy-of-the-eye-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-are-astigmatism-contacts-weighted-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/are-astigmatism-contacts-weighted.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-are-astigmatism-contacts-weighted-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-are-stitches-used-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/are-stitches-used-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-are-stitches-used-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-are-you-awake-during-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/are-you-awake-during-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-are-you-awake-during-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-astigmatism-from-concussion-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/astigmatism-from-concussion.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-astigmatism-from-concussion-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-astigmatism-risk-factors-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/astigmatism-risk-factors.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-astigmatism-risk-factors-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-astigmatism-worse-when-tired-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/astigmatism-worse-when-tired.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-astigmatism-worse-when-tired-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-best-cataract-lens-for-astigmatism-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/best-cataract-lens-for-astigmatism.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-best-cataract-lens-for-astigmatism-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-best-cataract-lens-for-night-driving-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/best-cataract-lens-for-night-driving.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-best-cataract-lens-for-night-driving-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-best-cataract-surgery-for-astigmatism-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/best-cataract-surgery-for-astigmatism.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-best-cataract-surgery-for-astigmatism-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-best-eye-drops-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/best-eye-drops-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-best-eye-drops-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-best-reading-glasses-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/best-reading-glasses-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-best-reading-glasses-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-blood-in-eye-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/blood-in-eye-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-blood-in-eye-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-blurred-vision-2-years-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/blurred-vision-2-years-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-blurred-vision-2-years-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-blurry-vision-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/blurry-vision-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-blurry-vision-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-blurry-vision-after-icl-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/blurry-vision-after-icl-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-blurry-vision-after-icl-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-blurry-vision-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/blurry-vision-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-blurry-vision-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-blurry-vision-after-prk-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/blurry-vision-after-prk.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-blurry-vision-after-prk-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-brain-adjusting-to-monovision-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/brain-adjusting-to-monovision.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-brain-adjusting-to-monovision-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-can-keratoconus-be-cured-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/can-keratoconus-be-cured.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-can-keratoconus-be-cured-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-can-lasik-fix-farsightedness-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/can-lasik-fix-farsightedness.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-can-lasik-fix-farsightedness-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-can-you-get-cataracts-at-a-young-age-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/can-you-get-cataracts-at-a-young-age.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-can-you-get-cataracts-at-a-young-age-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-can-you-get-lasik-while-pregnant-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/can-you-get-lasik-while-pregnant.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-can-you-get-lasik-while-pregnant-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-can-you-refuse-eye-dilation-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/can-you-refuse-eye-dilation.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-can-you-refuse-eye-dilation-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-can-you-see-during-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/can-you-see-during-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-can-you-see-during-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-cataract-glare-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/cataract-glare.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-cataract-glare-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-cataract-surgery-cause-macular-degeneration-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/cataract-surgery-cause-macular-degeneration.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-cataract-surgery-cause-macular-degeneration-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-cataract-surgery-in-both-eyes-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/cataract-surgery-in-both-eyes.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-cataract-surgery-in-both-eyes-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-cataracts-genetic-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/cataracts-genetic.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-cataracts-genetic-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-chlorine-burning-eyes-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/chlorine-burning-eyes.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-chlorine-burning-eyes-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-computer-screen-blurry-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/computer-screen-blurry-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-computer-screen-blurry-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-cooking-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/cooking-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-cooking-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-corneal-cross-linking-age-limit-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/corneal-cross-linking-age-limit.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-corneal-cross-linking-age-limit-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-corneal-cross-linking-procedure-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/corneal-cross-linking-procedure.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-corneal-cross-linking-procedure-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-corneal-cross-linking-recovery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/corneal-cross-linking-recovery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-corneal-cross-linking-recovery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-corneal-cross-linking-success-rate-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/corneal-cross-linking-success-rate.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-corneal-cross-linking-success-rate-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-cross-linking-side-effects-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/cross-linking-side-effects.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-cross-linking-side-effects-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-disadvantages-of-icl-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/disadvantages-of-icl-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-disadvantages-of-icl-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-dizzy-and-trouble-focusing-eyes-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/dizzy-and-trouble-focusing-eyes.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-dizzy-and-trouble-focusing-eyes-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-do-cataracts-grow-back-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/do-cataracts-grow-back.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-do-cataracts-grow-back-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-do-eyes-look-different-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/do-eyes-look-different-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-do-eyes-look-different-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-does-astigmatism-get-worse-with-age-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/does-astigmatism-get-worse-with-age.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-does-astigmatism-get-worse-with-age-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-does-blue-light-damage-eyes-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/does-blue-light-damage-eyes.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-does-blue-light-damage-eyes-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-does-cross-linking-hurt-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/does-cross-linking-hurt.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-does-cross-linking-hurt-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-does-cross-linking-improve-vision-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/does-cross-linking-improve-vision.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-does-cross-linking-improve-vision-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-does-lasik-hurt-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/does-lasik-hurt.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-does-lasik-hurt-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-does-lasik-wear-off-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/does-lasik-wear-off.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-does-lasik-wear-off-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-does-smoking-make-cataracts-worse-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/does-smoking-make-cataracts-worse.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-does-smoking-make-cataracts-worse-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-does-the-corneal-flap-heal-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/does-the-corneal-flap-heal-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-does-the-corneal-flap-heal-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-dos-and-donts-after-icl-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/dos-and-donts-after-icl-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-dos-and-donts-after-icl-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-driving-after-eye-dilation-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/driving-after-eye-dilation.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-driving-after-eye-dilation-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-droopy-eyelid-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/droopy-eyelid-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-droopy-eyelid-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-dry-eye-from-contacts-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/dry-eye-from-contacts.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-dry-eye-from-contacts-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-evo-icl-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/evo-icl.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-evo-icl-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-exercise-after-icl-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/exercise-after-icl-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-exercise-after-icl-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-eye-makeup-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/eye-makeup-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-eye-makeup-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-eye-strain-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/eye-strain-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-eye-strain-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-eyelid-swelling-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/eyelid-swelling-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-eyelid-swelling-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-eyesight-getting-worse-in-20-s-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/eyesight-getting-worse-in-20s.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-eyesight-getting-worse-in-20-s-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-fine-tuning-vision-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/fine-tuning-vision-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-fine-tuning-vision-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-flap-dislocation-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/flap-dislocation-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-flap-dislocation-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-floaters-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/floaters-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-floaters-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-fluctuating-vision-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/fluctuating-vision-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-fluctuating-vision-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-flying-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/flying-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-flying-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-food-not-to-eat-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/food-not-to-eat-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-food-not-to-eat-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-glasses-after-cataract-surgery-one-eye-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/glasses-after-cataract-surgery-one-eye.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-glasses-after-cataract-surgery-one-eye-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-haircut-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/haircut-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-haircut-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-headache-after-icl-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/headache-after-icl-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-headache-after-icl-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-housework-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/housework-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-housework-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-does-lasik-work-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-does-lasik-work.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-does-lasik-work-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-after-cataract-surgery-can-you-bend-over-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-long-after-cataract-surgery-can-you-bend-over.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-after-cataract-surgery-can-you-bend-over-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-after-cataract-surgery-can-you-drive-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-long-after-cataract-surgery-can-you-drive.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-after-cataract-surgery-can-you-drive-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-after-prk-can-i-watch-tv-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-long-after-prk-can-i-watch-tv.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-after-prk-can-i-watch-tv-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-do-cataract-lenses-last-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-long-do-cataract-lenses-last.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-do-cataract-lenses-last-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-do-i-need-to-wear-dark-glasses-outside-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-long-do-i-need-to-wear-dark-glasses-outside-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-do-i-need-to-wear-dark-glasses-outside-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-does-a-scratched-eye-take-to-heal-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-long-does-a-scratched-eye-take-to-heal.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-does-a-scratched-eye-take-to-heal-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-does-blurred-vision-last-after-cross-linking-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-long-does-blurred-vision-last-after-cross-linking.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-does-blurred-vision-last-after-cross-linking-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-does-cataract-surgery-last-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-long-does-cataract-surgery-last.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-does-cataract-surgery-last-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-does-cataract-surgery-take-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-long-does-cataract-surgery-take.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-does-cataract-surgery-take-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-does-dry-eye-last-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-long-does-dry-eye-last-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-does-dry-eye-last-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-does-it-take-for-vision-to-stabilize-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-long-does-it-take-for-vision-to-stabilize-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-does-it-take-for-vision-to-stabilize-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-does-it-take-to-recover-from-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-long-does-it-take-to-recover-from-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-does-it-take-to-recover-from-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-does-lasik-take-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-long-does-lasik-take.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-does-lasik-take-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-does-photokeratitis-last-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-long-does-photokeratitis-last.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-does-photokeratitis-last-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-for-dilated-eyes-to-return-to-normal-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-long-for-dilated-eyes-to-return-to-normal.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-long-for-dilated-eyes-to-return-to-normal-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-much-does-lasik-eye-surgery-cost-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-much-does-lasik-eye-surgery-cost.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-much-does-lasik-eye-surgery-cost-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-often-should-you-go-to-the-eye-doctor-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-often-should-you-go-to-the-eye-doctor.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-often-should-you-go-to-the-eye-doctor-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-often-to-use-eye-drops-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-often-to-use-eye-drops-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-often-to-use-eye-drops-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-to-clean-eyelashes-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-to-clean-eyelashes-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-to-clean-eyelashes-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-to-improve-near-vision-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-to-improve-near-vision-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-to-improve-near-vision-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-to-reduce-glare-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-to-reduce-glare-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-to-reduce-glare-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-to-shower-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-to-shower-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-to-shower-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-to-speed-up-prk-recovery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-to-speed-up-prk-recovery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-to-speed-up-prk-recovery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-to-stop-rubbing-the-eyes-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-to-stop-rubbing-the-eyes.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-to-stop-rubbing-the-eyes-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-to-wash-face-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/how-to-wash-face-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-how-to-wash-face-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-i-got-water-in-my-eyes-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/i-got-water-in-my-eyes-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-i-got-water-in-my-eyes-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-icl-for-keratoconus-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/icl-for-keratoconus.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-icl-for-keratoconus-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-icl-surgery-cost-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/icl-surgery-cost.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-icl-surgery-cost-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-icl-surgery-side-effects-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/icl-surgery-side-effects.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-icl-surgery-side-effects-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-icl-vs-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/icl-vs-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-icl-vs-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-is-astigmatism-common-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/is-astigmatism-common.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-is-astigmatism-common-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-is-astigmatism-dangerous-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/is-astigmatism-dangerous.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-is-astigmatism-dangerous-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-is-astigmatism-genetic-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/is-astigmatism-genetic.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-is-astigmatism-genetic-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-is-blacklight-bad-for-your-eyes-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/is-blacklight-bad-for-your-eyes.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-is-blacklight-bad-for-your-eyes-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-is-laser-cataract-surgery-worth-the-cost-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/is-laser-cataract-surgery-worth-the-cost.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-is-laser-cataract-surgery-worth-the-cost-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-is-lasik-safe-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/is-lasik-safe.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-is-lasik-safe-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-is-reading-on-a-tablet-bad-for-your-eyes-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/is-reading-on-a-tablet-bad-for-your-eyes.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-is-reading-on-a-tablet-bad-for-your-eyes-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-5-years-later-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/lasik-5-years-later.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-5-years-later-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-before-pregnancy-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/lasik-before-pregnancy.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-before-pregnancy-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-over-55-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/lasik-over-55.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-over-55-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-side-effects-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/lasik-side-effects.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-side-effects-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-suction-ring-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/lasik-suction-ring.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-suction-ring-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-touch-up-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/lasik-touch-up-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-touch-up-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-touch-up-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/lasik-touch-up.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-touch-up-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-vs-prk-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/lasik-vs-prk.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-vs-prk-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-when-sick-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/lasik-when-sick.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-when-sick-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-while-breastfeeding-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/lasik-while-breastfeeding.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-while-breastfeeding-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-wrinkled-flap-symptoms-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/lasik-wrinkled-flap-symptoms.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lasik-wrinkled-flap-symptoms-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-late-complications-of-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/late-complications-of-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-late-complications-of-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lenses-for-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/lenses-for-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-lenses-for-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-light-sensitivity-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/light-sensitivity-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-light-sensitivity-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-long-term-effects-of-icl-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/long-term-effects-of-icl-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-long-term-effects-of-icl-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-long-term-light-sensitivity-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/long-term-light-sensitivity-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-long-term-light-sensitivity-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-loss-of-near-vision-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/loss-of-near-vision-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-loss-of-near-vision-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-makeup-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/makeup-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-makeup-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-monovision-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/monovision-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-monovision-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-monovision-with-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/monovision-with-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-monovision-with-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-nearsighted-vs-farsighted-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/nearsighted-vs-farsighted.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-nearsighted-vs-farsighted-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-no-drops-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/no-drops-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-no-drops-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-precautions-after-icl-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/precautions-after-icl-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-precautions-after-icl-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-prk-for-keratoconus-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/prk-for-keratoconus.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-prk-for-keratoconus-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-prk-haze-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/prk-haze.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-prk-haze-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-prk-types-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/prk-types.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-prk-types-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-problems-after-icl-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/problems-after-icl-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-problems-after-icl-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-problems-with-toric-lenses-for-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/problems-with-toric-lenses-for-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-problems-with-toric-lenses-for-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-punched-in-eye-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/punched-in-eye-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-punched-in-eye-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-recovery-after-icl-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/recovery-after-icl-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-recovery-after-icl-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-recovery-after-yag-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/recovery-after-yag-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-recovery-after-yag-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-restrictions-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/restrictions-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-restrictions-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-running-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/running-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-running-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-sex-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/sex-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-sex-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-should-i-wear-my-old-glasses-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/should-i-wear-my-old-glasses-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-should-i-wear-my-old-glasses-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-showering-and-washing-hair-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/showering-and-washing-hair-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-showering-and-washing-hair-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-side-effects-of-eye-dilation-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/side-effects-of-eye-dilation.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-side-effects-of-eye-dilation-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-sleeping-position-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/sleeping-position-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-sleeping-position-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-smile-eye-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/smile-eye-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-smile-eye-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-smile-vs-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/smile-vs-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-smile-vs-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-smoking-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/smoking-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-smoking-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-stages-of-keratoconus-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/stages-of-keratoconus.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-stages-of-keratoconus-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-success-rate-of-icl-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/success-rate-of-icl-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-success-rate-of-icl-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-sudden-blurry-vision-in-one-eye-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/sudden-blurry-vision-in-one-eye.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-sudden-blurry-vision-in-one-eye-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-swimming-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/swimming-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-swimming-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-swimming-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/swimming-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-swimming-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-swollen-eyelid-after-prk-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/swollen-eyelid-after-prk.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-swollen-eyelid-after-prk-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-symptoms-of-dislocated-lens-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/symptoms-of-dislocated-lens-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-symptoms-of-dislocated-lens-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-terrified-of-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/terrified-of-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-terrified-of-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-toothpaste-in-eye-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/toothpaste-in-eye.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-toothpaste-in-eye-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-training-eyes-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/training-eyes-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-training-eyes-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-types-of-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/types-of-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-types-of-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-vaping-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/vaping-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-vaping-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-vision-before-and-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/vision-before-and-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-vision-before-and-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-vision-imbalance-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/vision-imbalance-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-vision-imbalance-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-weight-lifting-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/weight-lifting-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-weight-lifting-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-welding-flash-burn-eyes-home-treatment-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/welding-flash-burn-eyes-home-treatment.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-welding-flash-burn-eyes-home-treatment-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-activities-should-be-avoided-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/what-activities-should-be-avoided-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-activities-should-be-avoided-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-are-cataracts-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/what-are-cataracts.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-are-cataracts-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-are-the-disadvantages-of-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/what-are-the-disadvantages-of-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-are-the-disadvantages-of-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-causes-keratoconus-to-get-worse-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/what-causes-keratoconus-to-get-worse.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-causes-keratoconus-to-get-worse-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-do-you-see-during-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/what-do-you-see-during-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-do-you-see-during-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-does-20-20-vision-mean-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/what-does-20-20-vision-mean.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-does-20-20-vision-mean-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-does-dilating-the-eyes-do-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/what-does-dilating-the-eyes-do.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-does-dilating-the-eyes-do-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-happens-if-you-rub-your-eyes-too-much-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/what-happens-if-you-rub-your-eyes-too-much.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-happens-if-you-rub-your-eyes-too-much-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-is-a-toric-icl-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/what-is-a-toric-icl.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-is-a-toric-icl-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-is-astigmatism-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/what-is-astigmatism.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-is-astigmatism-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-is-considered-strenuous-activity-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/what-is-considered-strenuous-activity-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-is-considered-strenuous-activity-after-cataract-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-is-dry-eye-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/what-is-dry-eye.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-is-dry-eye-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-is-glaucoma-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/what-is-glaucoma.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-is-glaucoma-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-is-icl-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/what-is-icl-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-is-icl-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-not-to-do-after-eye-dilation-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/what-not-to-do-after-eye-dilation.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-what-not-to-do-after-eye-dilation-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-when-can-i-wash-my-eyes-after-lasik-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/when-can-i-wash-my-eyes-after-lasik.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-when-can-i-wash-my-eyes-after-lasik-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-when-does-vision-stop-changing-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/when-does-vision-stop-changing.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-when-does-vision-stop-changing-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-when-is-a-cataract-ripe-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/when-is-a-cataract-ripe.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-when-is-a-cataract-ripe-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-when-should-i-take-my-child-to-the-eye-doctor-for-the-first-time-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/when-should-i-take-my-child-to-the-eye-doctor-for-the-first-time.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-when-should-i-take-my-child-to-the-eye-doctor-for-the-first-time-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-who-should-not-have-laser-eye-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/who-should-not-have-laser-eye-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-who-should-not-have-laser-eye-surgery-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-will-astigmatism-cause-double-vision-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/will-astigmatism-cause-double-vision.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-will-astigmatism-cause-double-vision-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-yard-work-after-cataract-surgery-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/posts/yard-work-after-cataract-surgery.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-posts-yard-work-after-cataract-surgery-mdx" */)
}

